<template>
  <div class="newsCategory">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <div class="newTitle">
        <p><strong>{{ options.title }}</strong></p>
        <p>{{ options.con}}</p>
      </div>
      <el-row v-for="(item, index) in options.content" :key="index" class="newItem">
        <el-col :span="2">
          <div class="date">
            <p><strong>{{ new Date(item.date).format('MM-DD') }}</strong></p>
            <p>{{ item.date }}</p>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="content">
            <p><strong>{{ item.title }}</strong></p>
            <p>{{ item.content }}</p>
            <p><router-link class="link" :to="{path: item.link, query: {id: item.id}}">了解更多</router-link></p>
          </div>
        </el-col>
        <el-col :span="4"><div class="image"><el-image :src="item.url"></el-image></div></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  components: {imageBanner},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/新闻资讯.png')
      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      if ( id ) {
        switch ( id ) {
          case '1':
            this.options = this.$store.getters.getCompany && JSON.parse(JSON.stringify(this.$store.getters.getCompany))
            console.log(this.options)
            break
          case '2':
            this.options = this.$store.getters.getIndustry && JSON.parse(JSON.stringify(this.$store.getters.getIndustry))
            // this.setOptions(option)
            break
          default:
            this.options = this.$store.getters.getCompany
        }
      }
    },
  }
}

</script>

<style scoped>
.newsCategory {background-color: #ecf0f5; padding-bottom: 1rem;}
.container .newTitle {padding: .5rem 0; border-bottom: 4px solid #ccc; color: #e67700; line-height: 2;}
.container .newTitle strong {color: rgba(51, 51, 51, .4); font-size: 2rem; }
.newItem {min-height: 5rem; padding: .5rem; border-bottom: 1px solid #ccc; line-height: 2; font-size: .8rem;}
.newItem .date strong {font-size: 1.8rem;}
.newItem .content p {padding: .2rem 0}
.newItem .content .link {display: inline-block; padding: .1rem 1rem; border: 1px solid #e67700; border-radius: 1rem; color: #e67700;}
.newItem .content .link:hover {background-color: #e67700; color: #eee;}
.newItem .image {width: 80%; margin: 0 auto;}
</style>